.question-bar {
    min-height: 50px;
    height: "auto";
    padding: 0 5px 0 5px;
    background-color: #fff;
    border: 2px solid #e9e9e9;
    border-radius: 25px;
    flex-shrink: 0;

    & .MuiOutlinedInput-notchedOutline {
        border: unset;
    }

    & .__input {
        flex-grow: 1;
        &.MuiTextarea-root {
            --Textarea-focusedHighlight: transparent;
        }
    }


    & .__button {
        &:hover {
            background-color: unset;
        }

        &.--no-padding {
            padding: 0;
        }

        &.--blue-background {
            background-color: rgba(0, 0, 0, 0.87);
        }

        &.--red-background {
            background-color: #eb5f5f;
        }

        &.--white-background {
            background-color: #fff;
        }
    }

    & .send-icon {
        font-size: 20px;
        color: #fff;
    }

    & .record-icon {
        font-size: 20px;
        color: #fff;
    }

    & .pause-icon {
        font-size: 20px;
        color: #fff;
    }
}