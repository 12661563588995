.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .MuiFormLabel-asterisk {
    display: none;
  }
  
  button.confirm-button {
    color: white;
    background-color: #003057;
    transition: background-color 0.3s ease, color 0.3s ease;
  
    &:hover {
      background-color: #004074;
    }
  }
  .main-page-container {
    padding: 20px;
    .MuiContainer-root {
      padding-top: 16px; // Adjust padding at the top to space the menu from the container
    }
  }

.account-menu-container {
    position: absolute;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Align to the right */
    z-index: 1000; /* Ensure it floats above other elements */
}

.last-card {
  position: relative;

  .account-menu-container {
      position: absolute;
      top: 0; /* Align to the top of the last card */
      right: -20px; /* Adjust to align to the right of the card */
  }
}

// body {
//   margin: 0;
//   height: 100dvh;
//   overflow: hidden;
// }
