.app-bar {
    &.MuiAppBar-root {
        width: 100%;
        background-color: #f0f0f0;
        height: 60px;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        transition: none;
        // padding: 10px 0;
        flex-direction: row;

        // Add media query for small screens
        @media (max-width: 600px) {
            height: auto;  // Remove the height constraint
        }
    }

    .cisp1-logo {
        height: 35px;
        width: auto;

        // Add media query for small screens
        @media (max-width: 600px) {
            height: auto;  // Remove specific height for small screens
        }
    }
}

.app-bar__container{
    margin-right: 5px;
}