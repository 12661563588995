body {
    margin: 0;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  pre {
    background-color: #2d2d2d; /* Dark background */
    color: #f8f8f2;            /* Light text */
    border-radius: 5px;
    overflow-x: auto;          /* Horizontal scroll for long code lines */
    max-width: 100%;
  }
  
  code {
    font-family: Consolas, 'Courier New', monospace; /* Monospaced font for code */
    font-size: 14px;
  }
  
  /* Inline code styling */
  p code {
    background-color: #f5f5f5; /* Light background for inline code */
    color: #d63384;            /* Distinct color for inline code */
    padding: 2px 5px;
    border-radius: 4px;
  }
  
  /* New style to center the content */
  .container {
    max-width: 800px; /* Maximum content width */
    margin: 0 auto;   /* Center horizontally */
  }

.messages-section {
    &>div:last-child {
        margin-bottom: 40px;
    }
}

/* Styles for smaller screens (Mobile view) */
@media (max-width: 600px) {
  .chat-container {
      height: 100vh;
      display: flex;
      flex-direction: column;
  }

  .app-bar {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
  }

  .messages-section {
      flex-grow: 1;
      overflow-y: auto;
      margin-top: 64px; /* Height of AppBar */
      margin-bottom: 56px; /* Height of QuestionBar */
  }

  // .question-bar {
  //     position: fixed;
  //     bottom: 0;
  //     width: 100%;
  //     z-index: 10;
  // }
}